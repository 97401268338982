<template>
  <div class="filesUpload">
    <div class="file" v-show="applyFileName">
      <img src="@/assets/icons/service/filesUpload.png" class="left-icon" alt="" srcset="">
      <div>
        <div class="title">
          <img class="icon" src="@/assets/icons/service/pdf-icon.png" alt="" srcset="">
          <div class="name">{{applyFileName}}<span v-if="uploadErr" class="err">上传失败</span></div>
          <el-button v-if="applyFilePath||uploadErr" @click="uploadAgain" type="text">重新上传 </el-button>
        </div>
        <el-progress class="progress" v-if="loadProgress>0&&loadProgress<100" :stroke-width='3' :show-text="false" :percentage="loadProgress" :color="uploadErr?'#FC7163':'$primaryColor'">
        </el-progress>
      </div>
    </div>
    <el-upload ref="elUpload" v-show="!applyFileName" drag :action="applyUpload" :headers="myHeader" :show-file-list="false" accept="application/pdf" class="upload" :on-success="applyUploadSuccess"
      :before-upload="applyHandlePicture" :on-error="hadlePoctureError" :on-progress="applyHandleProgress">
      <svg-icon class="icon" icon-class="upload-icon" />点击或拖拽文件到这里上传
    </el-upload>
  </div>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      default: '/portal/transaction/apply_file_upload'
    }
  },
  data () {
    return {
      applyUpload: '',
      myHeader: {
        Authorization: sessionStorage.getItem('token')
      },
      file: {},
      applyFilePath: '',
      applyFileName: '',
      uploadErr: false,
      loadProgress: 0
    }
  },
  created () {
    this.applyUpload = this.CONFIG.baseURL + this.url
  },
  methods: {
    hadlePoctureError () {
      this.uploadErr = true
    },
    applyUploadSuccess (res) {
      // 文件上传成功时
      if (res.code === '0') {
        this.applyFilePath = res.data.filePath
        this.$emit('uploadSuccess', {
          applyFilePath: this.applyFilePath,
          applyFileName: this.applyFileName
        })
      } else {
        this.applyFileName = ''
        this.$message.error(res.msg)
        this.uploadErr = true
      }
    },
    applyHandlePicture (file) {
      const isPDF = file.type === 'application/pdf'
      const isLt20M = file.size / 1024 / 1024 < 20
      this.uploadErr = false
      if (!isPDF) {
        this.$message.error('请上传PDF文件')
        return false
      }
      if (!isLt20M) {
        this.$message.error('上传PDF文件大小不能超过 20M!')
        return false
      }
      this.applyFileName = file.name
    },
    uploadAgain () {
      this.uploadErr = false
      this.$refs.elUpload.$children[0].handleClick()
    },
    applyHandleProgress (event) {
      this.loadProgress = Math.floor(event.percent)
    }
  }
}
</script>
<style lang="scss" scoped>
.filesUpload {
  font-size: 14px;
  color: #303b50;
  cursor: pointer;
  ::v-deep.upload {
    height: 116px;
    display: flex;
    align-items: center;
    justify-content: center;

    .el-upload-dragger {
      border: 2px dashed #91baff;
      width: 100%;
      height: 100%;
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        background: #f7faff;
        border: 2px dashed $primaryColor;
        .icon {
          color: $primaryColor;
        }
      }
    }
    .el-upload--picture-card:hover,
    .el-upload:focus {
      color: $primaryColor;
      border-color: $primaryColor;
    }
    .el-upload {
      width: 100%;
      height: 100%;
    }
    .icon {
      margin-right: 12px;
      width: 25px;
      height: 26px;
      color: #91baff;
    }
  }
  .file {
    height: 116px;
    background: #f7faff;
    border-radius: 2px;
    display: flex;
    padding-right: 24px;
    .left-icon {
      width: 24px;
      height: 116px;
      margin-right: 32px;
    }
    & > div {
      position: relative;
      width: 100%;
      .title {
        display: flex;
        align-items: center;
        width: 100%;
        height: 116px;
        .icon {
          width: 24px;
          height: 25px;
          margin-right: 12px;
        }
        .name {
          width: 100%;
          .err {
            color: #ed5448;
            margin-left: 12px;
          }
        }
        .el-button {
          flex-shrink: 0;
        }
      }
      .progress {
        position: absolute;
        height: 3px;
        width: 100%;
        bottom: 11px;
      }
    }
  }
}
</style>