<template>
  <div class="steps">
    <div class="step" :class="{active:item.step||item.step===0?(Array.isArray(item.step)?item.step.includes(active):active==item.step):active==index}" v-for="(item, index) in steps" :key="index">
      <div class="icons">
        <div class="icon">
          <svg-icon :icon-class="item.icon" />
        </div>
        <svg-icon class="line" icon-class="arrows-line" />
      </div>
      <div class="label">{{ item.name }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['active', 'steps'],
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.steps {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #b9bbbf;
  width: 100%;
  padding: 32px;
  padding-top: 8px;
  .step {
    white-space: nowrap;
    &.active {
      .icons {
        color: #aeb5be;
        .icon {
          background: $primaryColor;
          color: #fff;
        }
      }
      .label {
        color: #303b50;
      }
      & ~ .step {
        .icons {
          color: #aeb5be;
        }
        .label {
          color: #6c737b;
        }
      }
    }
    &:last-child {
      .line {
        display: none;
      }
    }

    .icons {
      line-height: 42px;
      display: flex;
      align-items: center;
      margin-bottom: 11px;
      position: relative;
      color: $primaryColor;
      .icon {
        height: 42px;
        width: 42px;
        font-size: 24px;
        text-align: center;
        margin: 0 12px;
        border-radius: 50%;
      }
      .line {
        height: 24px;
        width: 114px;
      }
    }
    .label {
      color: #303b50;
      font-size: 14px;
      width: 66px;
      text-align: center;
    }
  }
}
</style>