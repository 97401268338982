<template>
  <div class="checkData">
    <div class="icon">
      <img v-if="info.transactionEntity.status===2" src="@/assets/icons/service/check-success-icon.png" alt="">
      <img v-else src="@/assets/icons/service/check-fail-icon.png" alt="">
    </div>
    <div class="title" v-if="info.transactionEntity.status!=2">审核不通过</div>
    <div class="title" v-if="info.transactionEntity.status==2">
      业务单提交成功，请耐心等待审核！审核结果将发送短信至登陆账号（经办人手机号）{{info.transactionEntity.certType!==1 ?info.applyFormEntity.orgInfoEntity.agentMobile :info.applyFormEntity.personInfoEntity.agentMobile}}
    </div>
    <div class="hint" v-else>
      您提供的申请信息有误，请按照要求重新提交，如有疑问请联系：400-030-2013
    </div>
    <div class="info" v-if="info.transactionEntity.status===2">
      <div>
        <span class="label">业务单名称：</span>
        <span>{{info.transactionEntity.name}}</span>
      </div>
      <div>
        <span class="label">业务单号：</span>
        <span>{{info.transactionEntity.transactionNo}}</span>
      </div>
      <div>
        <span class="label">业务类型：</span>
        <span>{{transactionType(info.applyFormEntity.businessType)}}</span>
      </div>
    </div>
    <div class="fail" v-else>
      <div class="tt">审核不通过原因：</div>
      <div>
        {{info.auditReason}}
      </div>
    </div>
    <el-button type="primary" v-if="info.transactionEntity.status===2" @click="query" class="button">查看我的业务单</el-button>
    <!-- -if="info.applyFormEntity.businessType===1" -->
    <el-button type="primary" v-else @click="$parent.stepActive=0" class="button">修改并重新提交</el-button>
  </div>
</template>

<script>
import { transactionType } from '@/utils/typeMatch'
export default {
  props: ['info'],
  data () {
    return {
      transactionType
    }
  },
  methods: {
    query () {
      // this.$emit('query')
      // this.$parent.getTransactionDetail()
      this.$router.push({ name: 'serviceCenter', query: { type: 1 } })
    }
  },
  filters: {}
}
</script>
 
<style lang="scss" scoped>
.checkData {
  padding-top: 98px;
  text-align: center;
  color: #303b50;
  .icon {
    margin-bottom: 16px;
    img {
      width: 35px;
      height: 37px;
    }
  }
  .title {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 16px;
  }
  .hint {
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 16px;
  }
  .info {
    width: 848px;
    background: #f7faff;
    border-radius: 2px;
    margin: 0 auto;
    padding: 44px 74px;
    text-align: left;

    & > div {
      font-size: 14px;
      color: #303b50;
      line-height: 19px;
      margin-bottom: 14px;
      &:last-child {
        margin: 0;
      }
      .label {
        display: inline-block;
        width: 108px;
      }
    }
  }
  .fail {
    background: #fff7f7;
    color: #ed5448;
    width: 848px;
    padding: 32px 42px;
    font-size: 14px;
    margin: 0 auto;
    text-align: left;
    line-height: 24px;
    .tt {
      margin-bottom: 8px;
    }
  }
  .button {
    width: 180px;
    margin-top: 62px;
    margin-bottom: 64px;
  }
}
</style>