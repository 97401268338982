<template>
  <el-dialog class="agreement" @close="close" title="电子认证服务协议" top="10vh" center :visible.sync="dialogShow" width="902px">
    <div class="protocol" ref="scrollBox" @scroll="scroll">
      <div>
        <p>
          山东豸信认证服务有限公司（以下简称豸信CA）作为权威、可信、公正的第三方信任机构，根据国家相关法律、法规，为用户提供合法的数字证书申请、审核、签发和管理等电子认证服务。为保障数字证书申请人和豸信CA各方的合法权益，双方本着平等、自愿的原则，达成本协议，双方共同遵守执行。本协议内容源自《豸信CA电子认证业务规则》，如出现内容抵触，以《中华人民共和国电子签名法》及《豸信CA电子认证业务规则》为准。
        </p>
        <h2>一、用户的权利与责任</h2>
        <p>用户在申请数字证书时，须按照豸信CA的有关规定办理申请，提供真实、完整、准确的信息及证明材料；</p>
        <p>用户在获得数字证书时应及时检查证书所匹配的信息，如无异议则视为接受证书；</p>
        <p>用户应妥善保管豸信CA签发的数字证书和保护密码，不得泄漏或交付他人；且数字证书一律不得转让、转借或转用。</p>
        <p>如遇数字证书遗失、被窃，或数字证书私钥泄露，用户应当立即到豸信CA申请注销证书；豸信CA在接到用户的撤消申请后，在24小时内正式撤消用户的数字证书。用户必须自行承担在数字证书正式撤消之前所有使用数字证书造成的责任。</p>
        <p>如遇证书中的信息或用户信息发生变更，用户应当立即到豸信CA申请证书变更；</p>
        <p>所有使用证书在网上进行的活动均视为证书申请人所为，因此而产生的一切后果均由证书申请人负责，证书申请人必须遵守国家的相关规定。</p>
        <p>如果发生证书申请人死亡或单位停业或解散，不需要使用证书时。其法定责任人需要携带相关证明文件向豸信CA请求注销证书数字证书。相关责任人应当承担其数字证书在注销前产生的一切行为。有下列情况之一的，由用户承担责任：</p>
        <ul>
          <li>（1）故意或过失提供不真实资料，造成相关各方损失的；</li>
          <li>（2）因转让、转借或转用证书而产生的一切损失的；</li>
          <li>（3）用户不慎将数字证书丢失，或因故意、过失导致他人知道或遭盗用、冒用、伪造或者篡改造成相关各方损失的；</li>
          <li>（4）因用户信息发生变化且未及时通知豸信CA更新证书信息而造成损失的；</li>
          <li>（5）未按规定缴纳证书服务费用导致证书到期自动失效的。</li>
        </ul>
        <h2>二 、豸信CA的权利与责任</h2>
        <p>豸信CA完全遵照《豸信CA电子认证业务规则》及相关流程签发证书；</p>
        <p>证书签发权属于豸信CA；</p>
        <p>豸信CA采用国家密码管理局认可的证书存储介质。证书存储介质的保修期为一年。在保修期内经确认属于质量问题的，豸信CA提供免费的维修或更换服务；否则不提供保修服务；</p>
        <p>基于安全的理由，豸信CA有权要求用户及时更新数字证书。用户在收到更新通知时，应在规定的期限内到豸信CA更新证书。若用户逾期没有更新证书，所产生的后果由用户自行承担；</p>
        <p>豸信CA提供多种渠道的客户服务，包括语音、传真、电子邮件及网站等，详请以豸信CA网站\公告的信息为准；</p>
        <p>对于下列情形之一，豸信CA有权主动注销所签发的证书：</p>
        <ul>
          <li>（1）用户申请证书时提供不真实信息；</li>
          <li>（2）用户未按规定缴纳证书服务费用；</li>
          <li>（3）证书对应的私钥泄露或出现其他证书的安全性得不到保证的情况；</li>
          <li>（4）用户不能履行或违反了相关法律、法规和本协议所规定的责任和义务；</li>
          <li>（5）法律、法规规定的其他情形。</li>
        </ul>
        <p>用户在使用数字证书时必须遵守国家的法律、法规和行政规章制度。数字证书不能作为豸信CA规定使用范围外的其他任何用途，豸信CA不承担由此产生的任何责任。</p>
        <p>不对由于客观意外或其它不可抗拒事件造成的操作失败或延迟承担任何损失、损害或赔偿责任。</p>
        <p>在未获得用户同意前，豸信CA保证不将证书申请资料用于证书业务外的其它任何用途，并对证书信息外的其它信息进行保密。</p>
        <h2>三 、协议的生效、变更与终止</h2>
        <p>本协议的解释、修改权属于豸信CA；</p>
        <p>本协议如有修订，豸信CA会以电子邮件或网站等方式进行公告；</p>
        <p>本协议的有效期与证书有效期一致，若发生任何业务变更（例如办理续期、变更、冻结、解冻、注销等）且已重新签订协议的，以重新签订的协议为准并按新协议内容执行，原协议自动作废。</p>
        <h2>四 、争议</h2>
        <p>双方对本协议之规定发生争议时，应首先本着友好协商原则解决。若协商不成的，任何一方均可提请济南市仲裁委员会按照仲裁规则进行仲裁。仲裁裁决是终局的，对双方均具有约束力。</p>
        <h2>五 、附则</h2>
        <ul>
          <li>（1）证书申请人一旦递交经签名或盖章的证书申请表，则视作承认并遵守本协议，协议立即生效。</li>
          <li>（2）在网上申请同意本协议办理证书后，则视作承认并遵守本协议，协议立即生效。</li>
        </ul>
      </div>
    </div>
    <div class="button">
      <el-button @click="nextStep" type="primary" :disabled="countdown !== 0">同意并继续申请</el-button>
    </div>
  </el-dialog>
</template>
<script>
export default {
  name: 'serviceAgreement',
  model: {
    prop: 'show',
    event: 'showChange'
  },
  props: ['show'],
  data() {
    return {
      countdown: 10,
      readAgreement: '',
      dialogShow: false
    }
  },
  mounted() {},

  methods: {
    scroll(e) {
      //e.srcElement.scrollTop: 滚动条距离页面顶部距离
      //e.srcElement.clientHeight: 滚动页面高度
      //e.srcElement.scrollHeight: 滚动条高度
      if (
        e.srcElement.scrollTop + e.srcElement.clientHeight >
        e.srcElement.scrollHeight - 50
      ) {
        //-50距离底部50px是触发以下内容
        this.countdown = 0
      }
    },
    close() {
      this.$emit('showChange', false)
    },
    nextStep() {
      this.$emit('showChange', false)
      this.$emit('affirm', false)
    }
  },
  watch: {
    show() {
      if (!this.show) {
        this.$refs.scrollBox.scrollTop = 0
        this.countdown = 10
      }
      this.dialogShow = this.show
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep.agreement {
  .el-dialog__header {
    padding: 32px 0 24px 0px;
  }
  .el-dialog__title {
    font-size: 18px;
    font-weight: bold;
    color: #303b50;
    line-height: 24px;
    letter-spacing: 1px;
  }
  .el-dialog__body {
    padding: 0;
    text-align: left;
    .button {
      text-align: center;
      padding: 24px;
    }
  }
}
::v-deep.protocol {
  height: 60vh;
  overflow-y: scroll;
  padding: 0 48px;
  .el-scrollbar__wrap {
    overflow-x: hidden;
  }
  .el-scrollbar__view {
    padding: 0 48px;
  }
  // padding: 50px;

  h1 {
    font-size: 30px;
    color: #2a2b2c;
    text-align: center;
    line-height: 30px;
    padding-bottom: 35px;
  }

  p {
    font-size: 14px;
    color: #727887;
    line-height: 28px;
    text-indent: 2em;
  }

  h2 {
    font-size: 14px;
    color: #727887;
    padding-top: 20px;
    line-height: 24px;
  }

  h3 {
    font-size: 18px;
    color: #222222;
    line-height: 40px;
    text-indent: 1.7em;
  }

  li {
    font-size: 14px;
    color: #727887;
    line-height: 28px;
    text-indent: 4em;
  }
}
</style>
