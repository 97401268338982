<template>
  <div class="uploadFile">
    <div class="title">
      <span>业务单号：{{info.transactionEntity.transactionNo}}</span>
      <span>业务单名称：{{info.transactionEntity.name}}</span>
      <span>业务类型：{{transactionType(info.applyFormEntity.businessType)}}</span>
    </div>
    <div class="box">
      <div class="help">
        <div class="tt">文件上传要求：</div>
        <div class="hint">请按照如下要求准备资料，将全部资料<span class="red">扫描成一份</span>清晰的.pdf文件上传，文件大小小于20MB。</div>
        <div v-if="info.applyFormEntity.certType!==1">
          <div class="helpInfo">（1）将下载的<u class="file" @click="downloadApply">《{{certApplyFormFileName}}》</u>填写详细，并加【盖公章】<i class="viewExamples" @click="examplePreview"> | 查看示例</i></div>
          <div class="helpInfo">（2）提供<u>【机构证件复印件】</u>一份，并加【盖公章】<i class="viewExamples" @click="examplePreview('businesslicense')"> | 查看示例</i></div>
          <div class="helpInfo">（3）提供<u>【法人】</u>的有效<u>【身份证反正面复印件】（人像面和国徽面）</u>，并加【盖公章】<i class="viewExamples" @click="examplePreview('IDcard','a')"> | 查看示例</i></div>
          <div class="helpInfo">（4）提供<u>【经办人】</u>的有效<u>【身份证反正面复印件】（人像面和国徽面）</u>，并加【盖公章】<i class="viewExamples" @click="examplePreview('IDcard','b')"> | 查看示例</i></div>
          <div v-if="info.transactionEntity.certType!==2">（5）提供<u>【申请人】</u>的有效<u>【身份证反正面复印件】（人像面和国徽面）</u>，并加【盖公章】<i class="viewExamples" @click="examplePreview('IDcard','c')"> | 查看示例</i></div>
        </div>
        <div v-else>
          <div v-if="info.isEqualToCurrentLoginUser">
            <div class="helpInfo">（1）将下载的<u class="file" @click="downloadApply">《{{certApplyFormFileName}}》</u>填写详细，并【签名】<i class="viewExamples" @click="examplePreview"> | 查看示例</i></div>
            <div class="helpInfo">（2）提供<u>【本人】</u>的有效<u>【身份证反正面复印件】（人像面和国徽面）</u><i class="viewExamples" @click="examplePreview('IDcard')"> | 查看示例</i></div>
          </div>
          <div v-else>
            <div class="helpInfo">（1）将下载的<u class="file" @click="downloadApply">《{{certApplyFormFileName}}》</u>填写详细，并【签名】<i class="viewExamples" @click="examplePreview"> | 查看示例</i></div>
            <div class="helpInfo">（2）将下载的<u class="file"
                @click="downloadPDF('https://yhca-pro-public-download.oss-cn-hangzhou.aliyuncs.com/document/%E8%AF%81%E4%B9%A6%E8%A7%A3%E9%94%81%E7%94%B3%E8%AF%B7%E6%8E%88%E6%9D%83%E5%A7%94%E6%89%98%E4%B9%A6.pdf','证书解锁申请授权委托书')">《证书解锁申请授权委托书》</u>填写详细，并【签名】<u
                class="file" @click="examplePreview('mandate')"> | 查看示例</u>
            </div>
            <div class="helpInfo">（3）提供<u>【本人】</u>的有效<u>【身份证反正面复印件】（人像面和国徽面）</u><i class="viewExamples" @click="examplePreview('IDcard','d')"> | 查看示例</i></div>
            <div class="helpInfo">（4）提供<u>【经办人】</u>的有效<u>【身份证反正面复印件】（人像面和国徽面）</u><i class="viewExamples" @click="examplePreview('IDcard','b')"> | 查看示例</i></div>
          </div>
        </div>
      </div>
      <FilesUpload @uploadSuccess="uploadSuccess" />
      <el-button class="submitButn" @click="submit" type="primary">提交</el-button>
    </div>
    <el-dialog :append-to-body="true" :show-close="false" class="dialog-pdf" :visible.sync="dialog_pdf" top="2%" width="800px">
      <PdfPreview class="pdf-box" :url="pdfPreviewUrl"></PdfPreview>
    </el-dialog>
    <el-dialog :title="'下载《'+certApplyFormFileName+'》'" :show-close="false" class="dialog-download-Form" :visible.sync="dialogDownloadFormShow" top="10%" width="640px">
      <div class="applicationExample">
        <img src="./../../../assets/image/uploadFile/Org1.png" v-if="info.applyFormEntity.certType===2">
        <img src="./../../../assets/image/uploadFile/Individuals1.png" v-else>
        <span>参考示例</span>
        <div class="applicationPreview" @click="examplePreview">点击预览</div>
      </div>
      <div class="fillingInstructions">
        <h2>申请表填写说明</h2>
        <ul>
          <li>按照申请表要求填写内容以及准备材料</li>
          <li>加盖申请机构公章</li>
          <li v-if="info.applyFormEntity.sealInfoEntitys">请在印章采集表内加盖印模图样</li>
          <li v-if="info.applyFormEntity.sealInfoEntitys">每个申请表只支持加盖一个印模图样，请确认加盖的印模图样清晰可辨</li>
        </ul>
        <img src="./../../../assets/icons/service/fillingSteps.png">
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="applicationCancel">稍后下载</el-button>
        <el-button type="primary" size="small" @click="downloadApply">下载申请表</el-button>
      </span>
    </el-dialog>
    <el-dialog title="下载《证书解锁授权委托书》" :show-close="false" class="dialog-download-Form" :visible.sync="dialogDownloadShow" top="10%" width="640px" v-if="!info.isEqualToCurrentLoginUser">
      <div class="applicationExample">
        <img src="./../../../assets/image/uploadFile/mandate.png">
        <span>参考示例</span>
        <div class="applicationPreview" @click="examplePreview('mandate')">点击预览</div>
      </div>
      <div class="fillingInstructions">
        <h2>填写说明</h2>
        <ul>
          <li>按照委托书要求填写内容</li>
          <li>授权人签字</li>
          <li>本授权书仅限成功解锁1次</li>
          <li>此委托书与其他解锁资料一并扫描上传</li>
        </ul>
        <img src="./../../../assets/icons/service/mandateSteps.png">
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="mandateCancel">稍后下载</el-button>
        <el-button type="primary" size="small"
          @click="downloadPDF('https://yhca-pro-public-download.oss-cn-hangzhou.aliyuncs.com/document/%E8%AF%81%E4%B9%A6%E8%A7%A3%E9%94%81%E7%94%B3%E8%AF%B7%E6%8E%88%E6%9D%83%E5%A7%94%E6%89%98%E4%B9%A6.pdf','证书解锁授权委托书')">
          下载委托书</el-button>
      </span>
    </el-dialog>
    <div class="exampleExhibition" v-if="exampleShow">
      <h2>示例预览</h2>
      <div class="exampleExhibitionMain">
        <div v-for="(item,index) in exampleImg" :key="index">
          <img :src="require(`@/assets/image/uploadFile/${item}`)">
        </div>
        <div class="uniqueExample" v-if="isIDcard">
          <p v-if="identityCategory==='a'">法人身份证复印件（可彩印/可黑白打印）加盖公章</p>
          <p v-if="identityCategory==='b'">经办人身份证复印件（可彩印/可黑白打印）加盖公章</p>
          <p v-if="identityCategory==='c'">申请人身份证复印件（可彩印/可黑白打印）加盖公章</p>
          <p v-if="identityCategory==='d'">本人身份证复印件（可彩印/可黑白打印）加盖公章</p>
          <img :src="require(`@/assets/image/uploadFile/IDcard.png`)">
        </div>
        <div class="uniqueExample" v-if="isBusinesslicense">
          <p>机构证件复印件（可彩印/可黑白打印）加盖公章</p>
          <img class="uniqueImg" :src="require(`@/assets/image/uploadFile/businesslicense.png`)">
        </div>
      </div>
      <div class="closeExample">
        <el-button type="primary" size="small" @click="closeExample">关闭示例</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { transactionType } from '@/utils/typeMatch'
import FilesUpload from './filesUpload.vue'
export default {
  components: { FilesUpload },
  props: ['info'],
  data () {
    return {
      transactionType,
      projectList: [],
      dialog_pdf: false,
      pdfPreviewUrl: '',
      file: {},
      certApplyForm: '',
      certApplyFormFileName: '',
      certApplyFormFileSuffix: '',
      dialogDownloadFormShow: false,
      dialogDownloadShow: false,
      organizationCert: ['Org1.png', 'Org2.png'],
      organizationSeal: ['Org1.png', 'Org2.png', 'Org3.png'],
      InstitutionalIndividualsCert: [
        'Individuals1.png',
        'Individuals2.png',
        'Individuals3.png'
      ],
      InstitutionalIndividualsSeal: [
        'Individuals1.png',
        'Individuals2.png',
        'Individuals3.png',
        'Individuals4.png'
      ],
      mandate: ['mandate.png'],
      exampleShow: false,
      exampleImg: [],
      isIDcard: false,
      isBusinesslicense: false,
      identityCategory: ''
    }
  },
  created () {
    this.$api
      .getCertApplyForm({ transId: this.info.transactionEntity.id })
      .then(res => {
        this.certApplyForm = res
        let fileName = decodeURIComponent(
          res.headers['content-disposition'].split(';')[2].split('=')[1]
        )
        const reg = new RegExp('"', 'g')
        fileName = fileName.replace(reg, '')
        this.certApplyFormFileSuffix = fileName.substring(
          fileName.lastIndexOf('.')
        )
        this.certApplyFormFileName = fileName.substring(
          0,
          fileName.lastIndexOf('.')
        )
        this.dialogDownloadFormShow = true
      })
  },
  methods: {
    previewApply () {
      this.pdfPreviewUrl = URL.createObjectURL(this.applyFileRes.data)
      this.dialog_pdf = true
    },
    downloadApply () {
      let res = this.certApplyForm
      this.dialogDownloadFormShow = false

      this.$util.downloadFile(
        res.data,
        this.certApplyFormFileName + this.certApplyFormFileSuffix
      )
      if (!this.info.isEqualToCurrentLoginUser) {
        this.dialogDownloadShow = true
      }
    },
    uploadSuccess (file) {
      this.file = file
    },
    submit () {
      if (this.file && this.file.applyFilePath) {
        const loading = this.$loading({
          lock: true,
          text: '文件上传中',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        this.$api
          .saveApplyFileInfo({
            ...this.file,
            applyFileType: 2,
            transactionId: this.info.transactionEntity.id
          })
          .then(() => {
            loading.close()
            this.$parent.getTransactionDetail()
          })
          .catch(() => {
            loading.close()
          })
      } else {
        this.$message.error('请上传完整的申请资料')
      }
    },
    downloadPDF (url, name) {
      let xhr = new XMLHttpRequest()
      xhr.open('GET', url, true)
      xhr.responseType = 'blob'
      xhr.onload = function () {
        if (xhr.status === 200) {
          saveAs(xhr.response, name)
        }
      }
      xhr.send()
      function saveAs (blob, filename) {
        if (window.navigator.msSaveOrOpenBlob) {
          navigator.msSaveBlob(blob, filename + '.pdf')
        } else {
          let link = document.createElement('a')
          let body = document.querySelector('body')

          link.href = window.URL.createObjectURL(blob)
          link.download = filename

          // fix Firefox
          link.style.display = 'none'
          body.appendChild(link)

          link.click()
          body.removeChild(link)

          window.URL.revokeObjectURL(link.href)
        }
      }
      this.dialogDownloadShow = false
    },
    applicationCancel () {
      this.dialogDownloadFormShow = false
    },
    mandateCancel () {
      this.dialogDownloadShow = false
    },
    examplePreview (info, category) {
      this.exampleImg = ''
      this.isIDcard = false
      this.isBusinesslicense = false
      if (info === 'mandate') {
        this.exampleImg = this.mandate
      } else if (info === 'businesslicense') {
        this.isBusinesslicense = true
      } else if (info === 'IDcard') {
        this.isIDcard = true
        this.identityCategory = category
      } else {
        if (
          this.info.applyFormEntity.certType === 2 &&
          this.info.applyFormEntity.sealInfoEntitys
        ) {
          this.exampleImg = this.organizationSeal
        } else if (this.info.applyFormEntity.certType === 2) {
          this.exampleImg = this.organizationCert
        } else if (
          this.info.applyFormEntity.certType === 3 &&
          this.info.applyFormEntity.sealInfoEntitys
        ) {
          this.exampleImg = this.InstitutionalIndividualsSeal
        } else if (this.info.applyFormEntity.certType === 3) {
          this.exampleImg = this.InstitutionalIndividualsCert
        } else {
          this.exampleImg = this.organizationCert
        }
      }
      this.exampleShow = true
    },
    closeExample () {
      this.exampleShow = false
    }
  }
}
</script>
<style lang="scss" scoped>
.uploadFile {
  padding-bottom: 32px;
  .title {
    font-size: 14px;
    color: #6c737b;
    line-height: 82px;
    border-bottom: 1px solid #dee2e8;
    padding: 0 32px;
    span {
      margin-right: 72px;
    }
  }
  .box {
    padding: 0px 100px;
    .help {
      background: #f7faff;
      border-radius: 2px;
      padding: 16px 32px;
      color: #303b50;
      font-size: 14px;
      color: #303b50;
      line-height: 19px;
      margin: 32px 0;
      .tt {
        font-weight: bold;
        font-size: 14px;
        margin-top: 0;
        margin-bottom: 12px;
      }
      .hint {
        font-size: 14px;
        color: #ed5448;
        line-height: 19px;
        margin-bottom: 20px;
        .red {
          font-weight: 600;
        }
      }
      & > div,
      .helpInfo {
        margin-bottom: 20px;
        &:last-child {
          margin: 0;
        }
      }
      .file {
        cursor: pointer;
        color: $primaryColor;
      }
      .viewExamples {
        color: $primaryColor;
        cursor: pointer;
      }
    }
    .submitButn {
      display: block;
      margin: 82px auto 0;
      width: 182px;
    }
  }
  ::v-deep.dialog-download-Form {
    .el-dialog__header {
      padding: 32px 32px 20px;
      .el-dialog__title {
        font-size: 14px;
        font-weight: bold;
        color: #303b50;
        line-height: 19px;
        letter-spacing: 1px;
      }
    }
    .el-dialog__header + .el-dialog__body {
      padding: 0 32px;
      margin-bottom: 20px;
      overflow: hidden;
    }
    .applicationExample {
      width: 229px;
      padding-top: 4px;
      text-align: center;
      position: relative;
      float: left;
      &:hover {
        .applicationPreview {
          display: block;
        }
      }
      img {
        width: 229px;
        display: inline-block;
        margin-bottom: 8px;
        box-shadow: 1px 1px 5px rgb(0 0 0 / 50%);
      }
      span {
        font-size: 14px;
        color: #6c737b;
        line-height: 19px;
      }
    }
    .applicationPreview {
      width: 229px;
      height: 325px;
      background: rgba(0, 0, 0, 0.56);
      position: absolute;
      top: 4px;
      text-align: center;
      font-size: 14px;
      color: #ffffff;
      line-height: 324px;
      display: none;
    }
    .fillingInstructions {
      float: left;
      padding-top: 4px;
      margin-left: 24px;
      h2 {
        font-size: 14px;
        color: #303b50;
        line-height: 19px;
      }
      ul {
        margin-top: 10px;
      }
      li {
        width: 290px;
        font-size: 14px;
        color: #303b50;
        list-style: none;
        padding-left: 13px;
        position: relative;
        margin-bottom: 20px;
        &::before {
          content: '';
          width: 5px;
          height: 5px;
          background: #d8d8d8;
          border-radius: 5px;
          position: absolute;
          top: 7px;
          left: 0;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
      img {
        margin-top: 40px;
        width: 261px;
      }
    }
    .hint {
      font-size: 14px;
      color: #303b50;
      line-height: 19px;
      letter-spacing: 1px;
      & > div:first-child {
        margin-bottom: 12px;
      }
      span {
        color: #ed5448;
      }
    }
    .dialog-footer {
      a {
        color: #fff;
      }
    }
  }
}
.exampleExhibition {
  width: 100%;
  height: 100%;
  background: #f0f3f5;
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  h2 {
    width: 100%;
    height: 60px;
    background: #ffffff;
    font-size: 18px;
    color: #303b50;
    line-height: 60px;
  }
}
.exampleExhibitionMain {
  width: 100%;
  height: calc(100vh - 120px);
  overflow-y: scroll;
  div {
    width: 920px;
    margin: 16px auto 24px;
  }
  img {
    width: 100%;
    display: block;
    margin-bottom: 16px;
  }
}
.closeExample {
  width: 100%;
  height: 60px;
  background: #ffffff;
  position: absolute;
  bottom: 0;
  .el-button {
    margin-top: 14px;
  }
}
.uniqueExample {
  width: 922px;
  height: 1305px;
  background: #ffffff;
  padding-top: 86px;
  p {
    font-size: 21px;
    color: #303b50;
    line-height: 27px;
    text-align: center;
    margin-bottom: 76px;
  }
  img {
    width: 623px;
    margin-left: 119px;
    float: left;
    display: inline-block;
  }
  .uniqueImg {
    width: 100%;
    margin-left: 0;
  }
}
</style>