<template>
  <div class="complete">
    <img class="icon" v-if="info.transactionEntity.status===11" src="@/assets/icons/service/check-success-icon.png" alt="">
    <img class="icon" v-else src="@/assets/icons/service/check-fail-icon.png" alt="">
    <template v-if="info.transactionEntity.status===11">
      <div class="title">{{`证书${businessType[info.applyFormEntity.businessType]}成功`}}</div>
      <div v-if="info.applyFormEntity.businessType===7" class="hint">密码已重置为“<span>11111111</span>”，请尽快登录豸信CA数字证书助手修改密码！如有问题请联系400-030-2013</div>
    </template>
    <template v-if="info.applyFormEntity.businessType===3">
      <div v-if="info.transactionEntity.status===14" class="title">证书续期失败</div>
      <div v-if="info.transactionEntity.status===14" class="hint">如有问题请联系400-030-2013</div>
    </template>
    <div v-if="info.transactionEntity.status===12" class="title">业务单已取消</div>
    <div v-if="info.transactionEntity.status===13" class="title">业务单已作废</div>
    <div class="info">
      <div>
        <span class="label">业务单名称：</span>
        <span>{{info.transactionEntity.name}}</span>
      </div>
      <div>
        <span class="label">业务类型</span>
        <span>{{transactionType(info.applyFormEntity.businessType)}}</span>
      </div>
      <div>
        <span class="label">业务单号：</span>
        <span>{{info.transactionEntity.transactionNo}}</span>
      </div>
      <div v-if="[2,3].includes(info.applyFormEntity.businessType)">
        <span class="label">{{info.applyFormEntity.businessType===2?'变更':'更新'}}后的证书序列号：</span>
        <span>{{certSn}}</span>
      </div>
      <div v-if="info.applyFormEntity.businessType===3">
        <span class="label">剩余有效时间：</span>
        <span>{{ `${indate(certEndTime)}（${certEndTime.split(' ')[0]}到期)` }}</span>
      </div>
      <div v-if="(info.applyFormEntity.businessType===3||info.applyFormEntity.businessType===2||info.applyFormEntity.businessType===4)&&info.applyFormEntity.sealInfoEntitys">
        <span class="label">印章图样：</span>
        <img :src="info.applyFormEntity.sealInfoEntitys[0].patternFilePath">
      </div>
    </div>
    <el-button v-if="info.transactionEntity.status===11&&certSn&&[3,1,2,9].includes(info.applyFormEntity.businessType)" type="primary" @click="getInfo" class="query-cert-button">查看证书</el-button>
    <CertDetails ref="certDetails" />
  </div>
</template>

<script>
import { transactionType } from '@/utils/typeMatch'
import CertDetails from '@/components/certDetails.vue'
export default {
  name: 'complete',
  props: ['info'],
  components: { CertDetails },
  data () {
    return {
      certSn: '',
      transactionType,
      businessType: {
        1: '申请',
        2: '变更',
        3: '续期',
        7: '解锁',
        4: '注销',
        9: '补办'
      },
      certEndTime: ''
    }
  },
  created () {
    if ([3, 1, 2, 9].includes(this.info.applyFormEntity.businessType)) {
      this.getNewCertSn()
    }
  },
  methods: {
    getNewCertSn () {
      let transId = this.$route.query.transId
      this.$api
        .getNewCertSn({
          transId
        })
        .then(res => {
          this.certSn = res.data.signCertSn
          this.certEndTime = res.data.certEndTime
        })
    },
    getInfo () {
      this.$refs.certDetails.getInfo(this.certSn)
    },
    indate (time) {
      let startTime = new Date().getTime()
      let endTime = new Date(time.replace(/-/g, '/')).getTime()
      let day = Math.floor((endTime - startTime) / (1000 * 3600 * 24))
      let years = Math.floor(Math.abs(day) / 365)
      return `${years ? years + '年' : ''}${day % 365}天`
    }
  }
}
</script>

<style lang="scss" scoped>
.complete {
  text-align: center;
  padding-top: 100px;
  padding-bottom: 64px;
  .icon {
    width: 36px;
    height: 36px;
    margin-bottom: 16px;
  }
  .title {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 16px;
    span {
      color: #ed5448;
    }
  }
  .hint {
    font-size: 14px;
    color: #6c737b;
    line-height: 19px;
    margin-bottom: 24px;
    span {
      color: $primaryColor;
    }
  }
  .info {
    width: 848px;
    background: #f7faff;
    border-radius: 2px;
    margin: 0 auto;
    padding: 44px 74px;
    text-align: left;

    & > div {
      font-size: 14px;
      color: #303b50;
      line-height: 19px;
      margin-bottom: 14px;
      &:last-child {
        margin: 0;
      }
      .label {
        display: inline-block;
        width: 148px;
      }
    }
    img {
      width: 70px;
      display: inline-block;
      vertical-align: text-top;
    }
  }
  .query-cert-button {
    width: 182px;
    margin-top: 90px;
  }
  .el-button {
    margin-bottom: 68px;
  }
}
</style>